import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Report } from 'app/shared/models/report.model';
import { constants } from 'app/config/constants';

@Injectable()
export class ReportsService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    public constants: constants
  ) { }

  public createReport(report: Report) {
    const query = {
      query: "CALL createReport(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?);",
      params: [
        report.idCliente, report.idSerie, report.modelo, report.marca,
        report.descripcion, report.idKey, report.fechaCreacion, report.nombre,
        report.empresa, report.telefono, report.ubicacionEquipo, report.correo
      ]
    };

    return this.http.post<any>(constants.url + 'dynamic', query, this.httpOptions);
  }
}
