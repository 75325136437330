import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { ChildrenItems } from "../../../../../WebView/src/app/sidebar/sidebar.component";

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
}


@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent {

    public menuItems: any[];

    private href: string = "";

    constructor(private router: Router) { }

    isNotMobileMenu(){
        if( window.outerWidth > 991){
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.href = this.router.url;
    }

    ngAfterViewInit(){
    }

    logOut(){
        sessionStorage.clear();
        this.router.navigate(['']);
    }
}
