import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgxLoadingModule } from 'ngx-loading';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';

import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ReportsComponent } from './reports/reports.component';
import { constants } from './config/constants';

import { PDFService } from './shared/services/pdf/pdf.service';
import { ReportsService } from './shared/services/reports/reports.service';


const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'reports',
        pathMatch: 'full',
    }, {
        path: 'reports',
        component: ReportsComponent
    }, {
        path: '**',
        component: ReportsComponent
    }
];

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(AppRoutes),
        NgbModule.forRoot(),
        HttpModule,
        ComponentsModule,
        HttpClientModule,
        NgxLoadingModule.forRoot({}),
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    declarations: [
        AppComponent,
        ReportsComponent,
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        constants,
        ReportsService,
        PDFService,
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule { }
