import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { constants } from 'app/config/constants';

import { PDFService } from 'app/shared/services/pdf/pdf.service';
import { Report } from 'app/shared/models/report.model';
import { ReportsService } from 'app/shared/services/reports/reports.service';

import jsPDF from 'jspdf';
import swal from 'sweetalert2';


declare var $: any;

@Component({
    selector: 'app-reports-cmp',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css'],
    providers: [NgbModal]
})

export class ReportsComponent implements OnInit, OnDestroy {
    public brands = [];
    public loading = false;
    public Marca;
    public marca_id = '2';
    public Modelo;
    public modelo_id = '4';
    public models: any = [];
    private previousBrand = -1;
    private previousModel = -1;
    public Problema="";
    private Reporte: Report;
    public Serie;
    public series = [];
    public pdf;
    private fileName;

    public brandsControl = new FormControl('', Validators.required);
    public modelsControl = new FormControl('', Validators.required);
    public seriesControl = new FormControl();

    public filteredBrands: any = [];
    public filteredModels: any = [];
    public filteredSeries: any = [];

    public reportForm: FormGroup;

    private subtractingProblemChars = 320;

    private httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        })
    };

    constructor(
        public router: Router,
        private http: HttpClient,
        private reportsService: ReportsService,
        private pdfService: PDFService,
    ) {
        sessionStorage.clear();

        this.cleanReport();
        this.loadBrands();
    }

    subtractProblemChars(val) {
        this.subtractingProblemChars = 320 - val.length;
    }

    ngOnInit() {
        // Init report form
        this.reportForm = new FormGroup({
            descripcion: new FormControl('', [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(300)
            ]),
            nombre: new FormControl('', [
                Validators.required,
                Validators.maxLength(30)
            ]),
            empresa: new FormControl('', [
                Validators.required,
                Validators.maxLength(150)
            ]),
            telefono: new FormControl(null, [
                Validators.required,
                Validators.min(11111111),
                Validators.max(99999999)
            ]),
            ubicacionEquipo: new FormControl('', [
                Validators.required,
                Validators.maxLength(250)
            ]),
            correo: new FormControl('', [
                Validators.required,
                Validators.pattern('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$'),
                Validators.maxLength(250)
            ]),
        });

        // Filter inputs in list
        this.brandsControl
            .valueChanges
            .subscribe(
                (value: any) => {
                    this.filteredBrands = this._filterBrands(value);

                    if (this.filteredBrands.length === 1 && this.filteredBrands[0].nombre == value) {
                        const selectedBrand = this.filteredBrands[0].idMarca;

                        this.changeDropDown(selectedBrand, -1, -1);
                    } else {
                        this.changeDropDown(-1, -1, -1);
                        $('#inputModel').val('');
                        $('#inputSerie').val('');
                        this.Reporte.idSerie = -1;
                    }
                }
            );

        this.modelsControl
            .valueChanges
            .subscribe(
                (value: any) => {
                    this.filteredModels = this._filterModels(value);

                    if (
                        this.filteredBrands.length >= 1 && this.filteredModels.length >= 1
                        && this.filteredModels[0].nombre == value
                    ) {
                        const selectedBrand = this.filteredModels[0].idMarca;
                        const selectedModel = this.filteredModels[0].idModelo;

                        this.changeDropDown(selectedBrand, selectedModel, -1);
                    } else {
                        $('#inputSerie').val('');
                        this.Reporte.idSerie = -1;
                    }
                }
            );

        this.seriesControl
            .valueChanges
            .subscribe(
                (value: any) => {
                    this.filteredSeries = this._filterSeries(value);

                    if (
                        this.filteredBrands.length >= 1 && this.filteredModels.length >= 1 && this.filteredSeries.length >= 1
                        && this.filteredSeries[0].nombre == value
                    ) {
                        this.Reporte.idSerie = this.filteredSeries[0].idSerie;
                    }
                }
            );
    }

    private _filterBrands(value): any[] {
        const filterValue = value.toLowerCase();
        return this.brands.filter(option => option.nombre.toLowerCase().includes(filterValue));
    }

    private _filterModels(value): any[] {
        const filterValue = value.toLowerCase();
        return this.models.filter(option => option.nombre.toLowerCase().includes(filterValue));
    }

    private _filterSeries(value): any[] {
        const filterValue = value.toLowerCase();
        return this.series.filter(option => option.nombre.toLowerCase().includes(filterValue));
    }

    ngOnDestroy() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('reports-page');
    }

    private changeDropDown(selectedBrand, selectedModel, selectedSerie) {
        if (selectedBrand !== this.previousBrand) {
            this.loadModels(selectedBrand);
            this.loadSeries(-1);
        } else if (selectedModel !== this.previousModel) {
            this.loadSeries(selectedModel);
        }

        this.previousBrand = selectedBrand;
        this.Reporte.idSerie = selectedSerie;
        this.previousModel = selectedModel;
    }

    private cleanReport() {
        this.Reporte = {
            idCliente: null,
            idSerie: null,
            marca: '',
            modelo: '',
            descripcion: '',
            idKey: 2,
            idEstado: null,
            fechaCreacion: '',
            nombre: '',
            empresa: '',
            telefono: null,
            ubicacionEquipo: '',
            correo: '',
        }
    }

    private createReport(): void {
        this.reportsService.createReport(this.Reporte)
            .subscribe(data => {
                this.savePDF(data[0][0].insertedTicketID);
            }, error => {
                this.loading = false;
                this.showErrorMsg('¡Error!', 'Error de comunicación, inténtelo más tarde.');
            });
    }

    private loadBrands() {
        this.http
            .get<any>(constants.url + 'api/Marcas')
            .subscribe(data => {
                this.brands = data;
                this.filteredBrands = data;
            });
    }

    private loadModels(idBrand) {
        const data = {
            query: 'SELECT * FROM Modelos WHERE Modelos.idMarca=?;',
            params: [idBrand]
        };

        this.http
            .post<any>(constants.url + 'dynamic', data, this.httpOptions)
            .subscribe(res => {
                this.models = res;
                this.filteredModels = res;
            });
    }

    private loadSeries(idModel) {
        const data = {
            query: 'SELECT * FROM Series WHERE Series.idModelo=?;',
            params: [idModel]
        };

        this.http
            .post<any>(constants.url + 'dynamic', data, this.httpOptions)
            .subscribe(res => {
                this.series = res;
                this.filteredSeries = res;
            });
    }

    private savePDF(reportCode) {
        this.fileName = `Reporte-${reportCode}-Suplidora-SA-${new Date().toISOString().slice(0, 10)}.pdf`;

        // A3 size page of PDF
        this.pdf = new jsPDF('l', 'mm', 'a3', true);

        // LOGO
        const img = new Image();
        img.src = '../../../assets/img/logo/suplidora_logo_hd.png';
        img.onload = () => {
            this.pdf.addImage(img, 'png',  0, -10, 80, 80,'',"FAST");

            // Header
            this.pdf.setFont('arial', 'regular');
            this.pdf.setFontSize(16);

            this.pdfService.createHeader(this.pdf, reportCode, 15);

            this.pdf.setFont('arial', 'regular');
            this.pdf.setFontSize(14);

            const body = {
                brand: this.brandsControl.value.toString(),
                model: this.modelsControl.value.toString(),
                serie: this.seriesControl.value ? this.seriesControl.value.toString() : '',
                details: this.Reporte.descripcion.toString(),
                name: this.Reporte.nombre.toString(),
                telephone: this.Reporte.telefono.toString(),
                email: this.Reporte.correo.toString(),
                company: this.Reporte.empresa.toString(),
                location: this.Reporte.ubicacionEquipo.toString(),
            }

            this.pdfService.createBody(this.pdf, body, 80);

            // this.pdf.save(this.fileName);

            this.sendFile(this.Reporte.correo, reportCode);
        }
    }

    public selectSerie(row) {
        this.Reporte.idSerie = row;
    }

    private sendFile(email, reportCode) {
        const temp = 'data:application/pdf;filename=generated.pdf;base64,'.length;
        const base64 = this.pdf.output('datauristring').slice(temp);

        const queryClient = {
            email,
            idBoleta: reportCode,
            tipoBoleta: 'Reporte',
            user: this.fileName.slice(0, this.fileName.length - 3),
            base64
        };

        this.http
            .post<any>(constants.MS2 + 'UploadPDF', queryClient, this.httpOptions)
            .subscribe(res => {
                const querySuplesa = {
                    email: constants.SUPLESA_EMAIL,
                    idBoleta: reportCode,
                    tipoBoleta: 'Reporte',
                    user: this.fileName.slice(0, this.fileName.length - 3),
                    base64
                };

                this.http
                    .post<any>(constants.MS2 + 'UploadPDF', querySuplesa, this.httpOptions)
                    .subscribe(res => {
                        this.loading = false;

                        this.showSuccessMsg('¡Envío exitoso!',
                            `El reporte N°: ${reportCode} ha sido enviado con éxito. ¡Gracias!`)
                            .then((value) => {
                                this.loading = true;

                                $('#brand').val('');
                                $('#model').val('');
                                $('#serie').val('');

                                this.cleanReport();
                                this.reportForm.reset();
                                window.location.reload();
                            });
                    }, error => {
                        this.loading = false;
                        this.showErrorMsg('¡Error!', 'Error de comunicación, inténtelo más tarde.');
                    });
            }, error => {
                this.loading = false;
                this.showErrorMsg('¡Error!', 'Error de comunicación, inténtelo más tarde.');
            });
    }

    public sendReport() {
        if (!this.reportForm.valid) {
            return;
        }

        if (!this.brandsControl.valid || !this.modelsControl.valid) {
            this.showErrorMsg('¡Datos faltantes!', 'Debe seleccionar al menos la marca y el modelo.');
            return;
        }

        const idSerie = this.series.filter((serie) => serie.nombre == this.seriesControl.value);

        this.Reporte = {
            ...this.Reporte,
            ...this.reportForm.value,
            marca: this.brandsControl.value,
            modelo: this.modelsControl.value,
            serie: this.seriesControl.value ? null : this.seriesControl.value
        };

        try {
            this.loading = true;

            const date = new Date();
            const dateObj = new Date(date.valueOf() - (date.getTimezoneOffset()) * 60000).toISOString();

            this.Reporte.fechaCreacion = dateObj.replace('T', ' ').slice(0, 20);
            this.createReport();
        } catch (e) {
            this.loading = false;
            this.showErrorMsg('¡Error!', 'Error de comunicación, inténtelo más tarde.');
        }
    }

    private showErrorMsg(title: string, msg: string) {
        return swal({
            title: title,
            text: msg,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-suplidora',
            type: 'error'
        });
    }

    private showSuccessMsg(title: string, msg: string) {
        return swal({
            title: title,
            text: msg,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-suplidora',
            type: 'success'
        });
    }

    // Get from control
    get correo() {
        return this.reportForm.get('correo')!;
    }

    get descripcion() {
        return this.reportForm.get('descripcion')!;
    }

    get empresa() {
        return this.reportForm.get('empresa')!;
    }

    get nombre() {
        return this.reportForm.get('nombre')!;
    }

    get telefono() {
        return this.reportForm.get('telefono')!;
    }

    get ubicacionEquipo() {
        return this.reportForm.get('ubicacionEquipo')!;
    }
}
