import { Injectable } from '@angular/core';


declare interface ReportBodyPDF {
  brand: string;
  model: string;
  serie: string;
  details: string;
  name: string;
  telephone: string;
  email: string;
  company: string;
  location: string;
}


@Injectable()
export class PDFService {
  constructor() { }

  public createBody(pdf, body: ReportBodyPDF, startY: number) {
    // First section
    pdf.setFont('arial', 'regular');
    pdf.setFontSize(16);

    pdf.text('1. Indique la marca, modelo y serie de su equipo:', 10, startY - 15);

    pdf.setFont('arial', 'regular');
    pdf.setFontSize(14);

    // First row
    pdf.text('Marca:', 10, startY);
    pdf.roundedRect(40, startY - 7, 100, 10, 5, 5, 'S');
    pdf.text(body.brand, 43, startY);

    pdf.text('Modelo:', 190, startY);
    pdf.roundedRect(220, startY - 7, 50, 10, 5, 5, 'S');
    pdf.text(body.model, 223, startY);

    pdf.text('Serie:', 275, startY);
    pdf.roundedRect(300, startY - 7, 50, 10, 5, 5, 'S');
    pdf.text(body.serie, 303, startY);

    // Second section
    pdf.setFont('arial', 'regular');
    pdf.setFontSize(16);

    pdf.text('2. Describa el problema(s) que está presentando su equipo:', 10, startY + 16);

    pdf.setFont('arial', 'regular');
    pdf.setFontSize(14);


    pdf.text('Detalles:', 10, startY + 25);
    pdf.roundedRect(10, startY + 29, 340, 30, 5, 5, 'S');

    const tempDetails = body.details.match(/.{1,160}/g);

    for (let index = 0; index < tempDetails.length; index++) {
      pdf.text(tempDetails[index], 15, startY + 25 + 10 * (index + 1));
    }

    // Third section
    pdf.setFont('arial', 'regular');
    pdf.setFontSize(16);

    pdf.text('3. Información adicional:', 10, startY + 70);

    pdf.setFont('arial', 'regular');
    pdf.setFontSize(14);

    // Second row
    pdf.text('Nombre:', 10, startY + 84);
    pdf.roundedRect(40, startY + 77, 100, 10, 5, 5, 'S');
    pdf.text(body.name, 43, startY + 84);

    pdf.text('Teléfono:', 190, startY + 84);
    pdf.roundedRect(220, startY + 77, 50, 10, 5, 5, 'S');
    pdf.text(body.telephone, 223, startY + 84);

    pdf.text('Empresa:', 275, startY + 84);
    pdf.roundedRect(300, startY + 77, 50, 10, 5, 5, 'S');
    pdf.text(body.company, 303, startY + 84);

    // Third row
    pdf.text('Correo:', 10, startY + 100);
    pdf.roundedRect(40, startY + 93, 100, 10, 5, 5, 'S');
    pdf.text(body.email, 43, startY + 100);

    pdf.text('Ubicación:', 190, startY + 100);
    pdf.roundedRect(220, startY + 93, 130, 10, 5, 5, 'S');
    pdf.text(body.location, 223, startY + 100);
  }

  public createHeader(pdf, idContract: string, startY: number) {
    // Information
    pdf.text('N° Reporte:', 223, startY + 15);
    pdf.roundedRect(220, startY + 20, 50, 10, 5, 5, 'S');
    pdf.text(idContract.toString(), 223, startY + 27);

    pdf.line(5, startY + 35, 400, startY + 35);

    pdf.setFont('arial', 'regular');
    pdf.setFontSize(16);
  }
}
